import { useParams } from 'react-router-dom';
import { AdminEditRetailerForm } from '../../../components/admin/AdminEditRetailerForm';
import { AdminPaper } from '../components';

const AdminEditRetailer = () => {
  const { retailerId } = useParams<{ retailerId?: string }>();

  return (
    <AdminPaper title={`${retailerId ? 'Edit' : 'Create'} Retailer`}>
      <AdminEditRetailerForm retailerId={retailerId} />
    </AdminPaper>
  );
};

export { AdminEditRetailer };
