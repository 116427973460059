import { CloudUpload, Edit } from '@mui/icons-material';
import { Button, Grid2 } from '@mui/material';
import { useState } from 'react';
import { HiddenInput } from '../../../components/globals';
import { AdminCropImages } from './AdminCropImages';

type Props = {
  text: string;
  action: 'upload' | 'edit';
  fileName: string;
  setImage: React.Dispatch<React.SetStateAction<File | undefined | null>>;
  disabled?: boolean;
};

const AdminEditImageButton = ({ text, action, fileName, setImage, disabled = false }: Props) => {
  const [imageToCrop, setImageToCrop] = useState<File>();

  return (
    <>
      <Grid2 size={{ xs: 4, sm: 2 }}>
        <Button
          fullWidth
          sx={{ height: '100%' }}
          component='label'
          variant='contained'
          disabled={disabled}
          color={action === 'edit' ? 'secondary' : 'primary'}
          startIcon={action === 'edit' ? <Edit /> : <CloudUpload />}
        >
          {action === 'edit' ? 'Edit' : 'Upload'} {text}
          <HiddenInput
            type='file'
            accept='image/*'
            onChange={(event) => setImageToCrop(event.target.files?.length ? event.target.files[0] : undefined)}
          />
        </Button>
      </Grid2>
      <AdminCropImages
        isOpen={!!imageToCrop}
        image={imageToCrop}
        fileName={fileName}
        aspect={fileName.endsWith('-hero.png') ? 3.26 : undefined}
        cancelAction={() => setImageToCrop(undefined)}
        confirmAction={(croppedImage) => {
          setImage(croppedImage);
          setImageToCrop(undefined);
        }}
      />
    </>
  );
};

export { AdminEditImageButton };
