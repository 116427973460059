import { QueryParams, Retailer } from '../globals/types';
import { api } from '../services';

const retailersApiPath = '/retailers';

type RetailerListResponse = {
  retailers: Retailer[];
  totalCount: number;
};

const useRetailers = () => {
  const fetchAllRetailers = async (
    params: QueryParams = { sortBy: 'name', sortDirection: 'asc' }
  ): Promise<RetailerListResponse> => {
    const { data, headers } = await api.get(retailersApiPath, { params });
    return { retailers: data, totalCount: headers['x-total-count'] };
  };

  const searchRetailers = async (term: string, params?: QueryParams): Promise<RetailerListResponse> => {
    const { data, headers } = await api.get(`${retailersApiPath}/search/${term}`, { params });
    return { retailers: data, totalCount: headers['x-total-count'] };
  };

  const fetchRetailer = async (retailerId: string): Promise<Retailer> => {
    const { data } = await api.get(`${retailersApiPath}/${retailerId}`);
    return data;
  };

  const createRetailer = async (retailer: Partial<Retailer>): Promise<Retailer> => {
    const { data } = await api.post(`${retailersApiPath}`, retailer);
    return data;
  };

  const editRetailer = async (retailer: Partial<Retailer> & { _id: string }): Promise<Retailer> => {
    const { data } = await api.patch(`${retailersApiPath}/${retailer._id}`, retailer);
    return data;
  };

  const deleteRetailer = async (retailerId: string): Promise<void> => {
    await api.delete(`${retailersApiPath}/${retailerId}`);
    return;
  };

  return { fetchAllRetailers, searchRetailers, fetchRetailer, createRetailer, editRetailer, deleteRetailer };
};

export { useRetailers };
