import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { countries, socialNetworks } from '../../globals/constants';
import { Brand, Country, Retailer } from '../../globals/types';
import { useBrands, useCloudflare, useRetailers } from '../../hooks';
import {
  AdminDeleteImageButton,
  AdminEditBrandModal,
  AdminEditImageButton,
  AdminError,
  AdminField,
  AdminFieldAutocomplete,
  AdminForm,
  AdminFormActions,
  AdminLoading,
  AdminViewImageButton
} from '../../pages/admin/components';
import { retailerSchema } from '../../pages/admin/retailers/schemas';

type Props = {
  retailerId?: string;
  onSuccess?: (retailer: Retailer) => void;
  onCancel?: () => void;
};

const AdminEditRetailerForm = ({ retailerId, onSuccess, onCancel }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createRetailer, editRetailer, fetchRetailer } = useRetailers();
  const { editBrand, fetchAllBrands } = useBrands();
  const { uploadImage, deleteImage } = useCloudflare();

  const [selectedBrands, setSelectedBrands] = useState<(Brand & { _id: string })[]>([]);
  const [isEditBrandModalOpen, setIsEditBrandModalOpen] = useState(false);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [logo, setLogo] = useState<File | null>();
  const [heroImage, setHeroImage] = useState<File | null>();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Retailer & { brands: (Brand & { _id: string })[] }>({ resolver: zodResolver(retailerSchema) });

  const {
    data: retailer,
    isLoading: isFetchingRetailer,
    error: fetchRetailerError
  } = useQuery({
    enabled: !!retailerId,
    queryKey: ['fetchRetailer', retailerId],
    queryFn: () => fetchRetailer(retailerId as string)
  });

  const {
    data: brands,
    isLoading: isFetchingAllBrands,
    error: fetchAllBrandsError
  } = useQuery({
    queryKey: ['fetchAllBrands'],
    enabled: !!retailer,
    queryFn: async () => {
      const allBrands = (await fetchAllBrands()).brands.map(({ _id, name, slug, retailers }) => ({
        _id,
        name,
        slug,
        retailers
      }));
      setSelectedBrands((prevSelectedBrands) => {
        return allBrands.filter(({ _id, retailers }) =>
          prevSelectedBrands.length
            ? prevSelectedBrands.some(({ _id: selectedId }) => _id === selectedId)
            : (retailers as string[]).includes(retailerId || '')
        );
      });
      return allBrands.concat({ _id: '', name: 'Other', slug: '', retailers: [] });
    }
  });

  const {
    isPending: isMutatingRetailer,
    mutate: mutateRetailer,
    error: mutateRetailerError
  } = useMutation({
    mutationFn: async (changes: Retailer) => {
      if (heroImage) {
        if (retailer?.heroImage) await deleteImage(retailer.heroImage);
        const heroImageId = await uploadImage(heroImage, { retailer: changes.slug, type: 'hero' });
        if (heroImageId) changes.heroImage = heroImageId;
      }
      if (heroImage === null && retailer?.heroImage) {
        await deleteImage(retailer.heroImage);
        changes.heroImage = '';
      }
      if (logo) {
        if (retailer?.logo) await deleteImage(retailer.logo);
        const logoImageId = await uploadImage(logo, { retailer: changes.slug, type: 'logo' });
        if (logoImageId) changes.logo = logoImageId;
      }
      if (logo === null && retailer?.logo) {
        await deleteImage(retailer.logo);
        changes.logo = '';
      }
      if (changes.url) changes.url = changes.url.replace(/^https?:\/\/www\./, 'https://').replace(/\/$/, '');
      return retailerId ? editRetailer({ ...changes, _id: retailerId }) : createRetailer({ ...changes });
    },
    onSuccess: (data) => {
      if (retailerId) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchRetailer', retailerId] });
      if (onSuccess) {
        onSuccess(data);
      } else {
        navigate(-1);
      }
    }
  });

  const {
    isPending: isMutatingBrands,
    mutate: mutateBrand,
    error: mutateBrandsError
  } = useMutation({
    mutationFn: (changes: Brand) => editBrand(changes)
  });

  const handleUrlChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trim().toLowerCase();
      setValue('url', `${!['https://', 'http://'].some((pref) => text.startsWith(pref)) ? 'https://' : ''}${text}`);
    },
    [setValue]
  );

  const isLoading = useMemo(
    () => isFetchingRetailer || isMutatingRetailer || isFetchingAllBrands || isMutatingBrands,
    [isFetchingRetailer, isMutatingRetailer, isFetchingAllBrands, isMutatingBrands]
  );

  const error = useMemo(
    () => fetchRetailerError || fetchAllBrandsError || mutateRetailerError || mutateBrandsError,
    [fetchRetailerError, fetchAllBrandsError, mutateRetailerError, mutateBrandsError]
  );

  const onSubmit = (data: Retailer & { brands: (Brand & { _id: string })[] }) => {
    selectedBrands.forEach((brand) => {
      if (brand._id) {
        mutateBrand({ ...brand, retailers: [...(brand.retailers as string[]), String(retailerId)] });
      }
    });
    mutateRetailer(data);
  };

  useEffect(() => {
    setIsSlugValid(!!retailer?.slug);
  }, [retailer?.slug]);

  useEffect(() => {
    setValue('brands', selectedBrands, { shouldDirty: true });
  }, [selectedBrands, setValue]);

  return (
    <Container>
      <AdminLoading isLoading={isLoading} />
      <AdminError error={error} />
      {!isLoading && !error ? (
        <AdminForm onSubmit={handleSubmit(onSubmit)}>
          <AdminField
            id='name'
            control={control}
            defaultValue={retailer?.name}
            error={!!errors.name}
            helperText={errors.name?.message}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
              setValue('name', e.target.value);
            }}
          />
          <AdminField
            id='slug'
            control={control}
            defaultValue={retailer?.slug}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
            }}
            error={!!errors.slug}
            helperText={errors.slug?.message}
          />
          <AdminField
            id='url'
            type='url'
            control={control}
            defaultValue={retailer?.url}
            error={!!errors.url}
            helperText={errors.url?.message}
            onChange={handleUrlChanges}
          />
          <AdminField
            id='popularity'
            type='number'
            sm={3}
            control={control}
            defaultValue={retailer?.popularity}
            error={!!errors.popularity}
            helperText={errors.popularity?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('popularity', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('popularity', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <AdminField
            id='scraperPriority'
            type='number'
            sm={3}
            control={control}
            defaultValue={retailer?.scraperPriority}
            error={!!errors.scraperPriority}
            helperText={errors.scraperPriority?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('scraperPriority', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('scraperPriority', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <AdminField
            id='description'
            control={control}
            defaultValue={retailer?.description}
            sm={12}
            rows={6}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <AdminViewImageButton
            text='View Hero'
            imageId={retailer?.heroImage}
          />
          <AdminEditImageButton
            key='Edit Hero'
            text='Hero'
            action={heroImage || retailer?.heroImage ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${retailer?.slug || 'retailer'}-hero.png`}
            setImage={setHeroImage}
          />
          <AdminDeleteImageButton
            disabled={(!heroImage && !retailer?.heroImage) || heroImage === null}
            onDelete={() => setHeroImage(null)}
            text='Hero'
          />
          <AdminViewImageButton
            text='View Logo'
            imageId={retailer?.logo}
          />
          <AdminEditImageButton
            key='Edit Logo'
            text='Logo'
            action={logo || retailer?.logo ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${retailer?.slug || 'retailer'}-logo.png`}
            setImage={setLogo}
          />
          <AdminDeleteImageButton
            disabled={(!logo && !retailer?.logo) || logo === null}
            onDelete={() => setLogo(null)}
            text='Logo'
          />
          <AdminFieldAutocomplete
            id='country'
            sm={6}
            control={control}
            options={countries}
            defaultValue={retailer?.country}
            error={!!errors.country}
            helperText={errors.country?.message}
            onChange={(_, value) => setValue('country', value as Country)}
          />
          <AdminField
            id='state'
            control={control}
            defaultValue={retailer?.state}
            error={!!errors.state}
            helperText={errors.state?.message}
          />
          <AdminField
            id='city'
            control={control}
            defaultValue={retailer?.city}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <AdminField
            id='zipCode'
            control={control}
            defaultValue={retailer?.zipCode}
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
          />
          <AdminField
            id='address'
            control={control}
            defaultValue={retailer?.address}
            error={!!errors.address}
            helperText={errors.address?.message}
          />
          <AdminField
            id='email'
            type='email'
            control={control}
            defaultValue={retailer?.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <AdminField
            id='phoneNumber'
            type='tel'
            control={control}
            defaultValue={retailer?.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
          {socialNetworks.map((socialNetwork) => (
            <AdminField
              id={socialNetwork}
              key={socialNetwork}
              label={socialNetwork.replace(/^./, (str) => str.toUpperCase())}
              control={control}
              defaultValue={retailer ? retailer[socialNetwork] : undefined}
              error={!!errors[socialNetwork]}
              helperText={errors[socialNetwork]?.message}
            />
          ))}
          <AdminFieldAutocomplete
            id='brands'
            control={control}
            options={brands || []}
            value={selectedBrands}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsEditBrandModalOpen(true);
              }
              const newSelectedRetailers = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedBrands(newSelectedRetailers);
            }}
            getOptionLabel={(option) => (option as Retailer & { selected: boolean }).name}
            multiple={true}
            error={!!errors.brands}
            helperText={errors.brands?.message}
          />
          <AdminField
            id='notes'
            sm={12}
            rows={2}
            control={control}
            defaultValue={retailer?.notes}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
          <AdminFormActions
            saveAction={handleSubmit(onSubmit)}
            cancelAction={onCancel}
            isSaveButtonDisabled={!isDirty}
          />
        </AdminForm>
      ) : null}
      <AdminEditBrandModal
        isOpen={isEditBrandModalOpen}
        onSuccess={({ _id, name, slug }) => {
          setSelectedBrands((prev) => [...prev, { _id, name, slug }]);
          setIsEditBrandModalOpen(false);
        }}
        onCancel={() => setIsEditBrandModalOpen(false)}
      />
    </Container>
  );
};

export { AdminEditRetailerForm };
