import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { countries, socialNetworks } from '../../globals/constants';
import { Brand, Country, Retailer } from '../../globals/types';
import { useBrands, useCloudflare, useRetailers } from '../../hooks';
import { brandSchema } from '../../pages/admin/brands/schemas';
import {
  AdminDeleteImageButton,
  AdminEditImageButton,
  AdminError,
  AdminField,
  AdminFieldAutocomplete,
  AdminForm,
  AdminFormActions,
  AdminLoading,
  AdminQuickAddRetailerModal,
  AdminViewImageButton
} from '../../pages/admin/components';

type Props = {
  brandId?: string;
  onSuccess?: (brand: Brand) => void;
  onCancel?: () => void;
};

const AdminEditBrandForm = ({ brandId, onSuccess, onCancel }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createBrand, editBrand, fetchBrand } = useBrands();
  const { fetchAllRetailers } = useRetailers();
  const { uploadImage, deleteImage } = useCloudflare();

  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([]);
  const [isQuickAddRetailerOpen, setIsQuickAddRetailerOpen] = useState(false);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [logo, setLogo] = useState<File | null>();
  const [heroImage, setHeroImage] = useState<File | null>();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Brand>({ resolver: zodResolver(brandSchema) });

  const {
    data: brand,
    isLoading: isFetchingBrand,
    error: fetchBrandError
  } = useQuery({
    enabled: !!brandId,
    queryKey: ['fetchBrand', brandId || ''],
    queryFn: () => fetchBrand(brandId as string)
  });

  const {
    data: retailers,
    isLoading: isFetchingAllRetailers,
    error: fetchAllRetailersError
  } = useQuery({
    queryKey: ['fetchAllRetailers'],
    enabled: !!brand,
    queryFn: async () => {
      const allRetailers = (await fetchAllRetailers()).retailers.map(({ _id, name, slug }) => ({ _id, name, slug }));
      setSelectedRetailers((prev) =>
        allRetailers.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (brand?.retailers as string[]) || []).includes(_id)
        )
      );
      return allRetailers.concat({ _id: '', name: 'Other', slug: '' });
    }
  });

  const {
    isPending: isMutatingBrand,
    mutate: mutateBrand,
    error: mutateBrandError
  } = useMutation({
    mutationFn: async (changes: Brand) => {
      if (heroImage) {
        if (brand?.heroImage) await deleteImage(brand.heroImage);
        const heroImageId = await uploadImage(heroImage, { brand: changes.slug, type: 'hero' });
        if (heroImageId) changes.heroImage = heroImageId;
      }
      if (heroImage === null && brand?.heroImage) {
        await deleteImage(brand.heroImage);
        changes.heroImage = '';
      }
      if (logo) {
        if (brand?.logo) await deleteImage(brand.logo);
        const logoImageId = await uploadImage(logo, { brand: changes.slug, type: 'logo' });
        if (logoImageId) changes.logo = logoImageId;
      }
      if (logo === null && brand?.logo) {
        await deleteImage(brand.logo);
        changes.logo = '';
      }
      if (changes.url) changes.url = changes.url.replace(/^https?:\/\/www\./, 'https://').replace(/\/$/, '');
      return brandId ? editBrand({ ...changes, _id: brandId }) : createBrand({ ...changes });
    },
    onSuccess: (data) => {
      if (brandId) {
        queryClient.invalidateQueries({ exact: true, queryKey: ['fetchBrand', brandId] });
        queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllBrands'] });
      }
      if (onSuccess) {
        onSuccess(data);
      } else {
        navigate(-1);
      }
    }
  });

  const handleUrlChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trim().toLowerCase();
      setValue('url', `${!['https://', 'http://'].some((pref) => text.startsWith(pref)) ? 'https://' : ''}${text}`);
    },
    [setValue]
  );

  const isLoading = useMemo(
    () => isFetchingBrand || isFetchingAllRetailers || isMutatingBrand,
    [isFetchingBrand, isFetchingAllRetailers, isMutatingBrand]
  );

  const error = useMemo(
    () => fetchBrandError || fetchAllRetailersError || mutateBrandError,
    [fetchBrandError, fetchAllRetailersError, mutateBrandError]
  );

  const onSubmit = (data: Brand) => mutateBrand(data);

  useEffect(() => {
    setIsSlugValid(!!brand?.slug);
  }, [brand?.slug]);

  useEffect(() => {
    setValue(
      'retailers',
      selectedRetailers.map(({ _id }) => String(_id)),
      { shouldDirty: true }
    );
  }, [selectedRetailers, setValue]);

  return (
    <Container>
      <AdminLoading isLoading={isLoading} />
      <AdminError error={error} />
      {!isLoading && !error ? (
        <AdminForm onSubmit={handleSubmit(onSubmit)}>
          <AdminField
            id='name'
            control={control}
            defaultValue={brand?.name}
            error={!!errors.name}
            helperText={errors.name?.message}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
              setValue('name', e.target.value);
            }}
          />
          <AdminField
            id='slug'
            control={control}
            defaultValue={brand?.slug}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
            }}
            error={!!errors.slug}
            helperText={errors.slug?.message}
          />
          <AdminField
            id='url'
            type='url'
            control={control}
            defaultValue={brand?.url}
            error={!!errors.url}
            helperText={errors.url?.message}
            onChange={handleUrlChanges}
          />
          <AdminField
            id='popularity'
            type='number'
            sm={2}
            control={control}
            defaultValue={brand?.popularity}
            error={!!errors.popularity}
            helperText={errors.popularity?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('popularity', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('popularity', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <AdminField
            id='scraperPriority'
            type='number'
            sm={2}
            control={control}
            defaultValue={brand?.scraperPriority}
            error={!!errors.scraperPriority}
            helperText={errors.scraperPriority?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('scraperPriority', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('scraperPriority', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <AdminFieldAutocomplete
            id='scraperStatus'
            sm={2}
            control={control}
            options={['pending', 'in progress', 'blocked', 'ready']}
            defaultValue={brand?.scraperStatus}
            error={!!errors.scraperStatus}
            helperText={errors.scraperStatus?.message}
            onChange={(_, value) => setValue('scraperStatus', value as 'pending' | 'in progress' | 'blocked' | 'ready')}
          />
          <AdminField
            id='description'
            control={control}
            defaultValue={brand?.description}
            sm={12}
            rows={6}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <AdminViewImageButton
            text='View Hero'
            imageId={brand?.heroImage}
          />
          <AdminEditImageButton
            key='Edit Hero'
            text='Hero'
            action={heroImage || brand?.heroImage ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${brand?.slug || 'brand'}-hero.png`}
            setImage={setHeroImage}
          />
          <AdminDeleteImageButton
            disabled={(!heroImage && !brand?.heroImage) || heroImage === null}
            onDelete={() => setHeroImage(null)}
            text='Hero'
          />
          <AdminViewImageButton
            text='View Logo'
            imageId={brand?.logo}
          />
          <AdminEditImageButton
            key='Edit Logo'
            text='Logo'
            action={logo || brand?.logo ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${brand?.slug || 'brand'}-logo.png`}
            setImage={setLogo}
          />
          <AdminDeleteImageButton
            disabled={(!logo && !brand?.logo) || logo === null}
            onDelete={() => setLogo(null)}
            text='Logo'
          />
          <AdminFieldAutocomplete
            id='country'
            sm={6}
            control={control}
            options={countries}
            defaultValue={brand?.country}
            error={!!errors.country}
            helperText={errors.country?.message}
            onChange={(_, value) => setValue('country', value as Country)}
          />
          <AdminField
            id='state'
            control={control}
            defaultValue={brand?.state}
            error={!!errors.state}
            helperText={errors.state?.message}
          />
          <AdminField
            id='city'
            control={control}
            defaultValue={brand?.city}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <AdminField
            id='zipCode'
            control={control}
            defaultValue={brand?.zipCode}
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
          />
          <AdminField
            id='address'
            control={control}
            defaultValue={brand?.address}
            error={!!errors.address}
            helperText={errors.address?.message}
          />
          <AdminField
            id='email'
            type='email'
            control={control}
            defaultValue={brand?.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <AdminField
            id='phoneNumber'
            type='tel'
            control={control}
            defaultValue={brand?.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
          {socialNetworks.map((socialNetwork) => (
            <AdminField
              id={socialNetwork}
              key={socialNetwork}
              label={socialNetwork.replace(/^./, (str) => str.toUpperCase())}
              control={control}
              defaultValue={brand ? brand[socialNetwork] : undefined}
              error={!!errors[socialNetwork]}
              helperText={errors[socialNetwork]?.message}
            />
          ))}
          <AdminFieldAutocomplete
            id='retailers'
            control={control}
            options={retailers || []}
            value={selectedRetailers}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsQuickAddRetailerOpen(true);
              }
              const newSelectedRetailers = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedRetailers(newSelectedRetailers);
            }}
            getOptionLabel={(option) => (option as Retailer & { selected: boolean }).name}
            multiple={true}
            error={!!errors.retailers}
            helperText={errors.retailers?.message}
          />
          <AdminField
            id='notes'
            sm={12}
            rows={2}
            control={control}
            defaultValue={brand?.notes}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
          <AdminFormActions
            saveAction={handleSubmit(onSubmit)}
            cancelAction={onCancel}
            isSaveButtonDisabled={!isDirty}
          />
        </AdminForm>
      ) : null}
      <AdminQuickAddRetailerModal
        isOpen={isQuickAddRetailerOpen}
        setIsOpen={setIsQuickAddRetailerOpen}
        setSelectedRetailers={setSelectedRetailers}
      />
    </Container>
  );
};

export { AdminEditBrandForm };
