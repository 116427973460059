import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid2, Paper, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Credentials } from '../../globals/types';
import { useAuth } from '../../hooks';

const authSchema = z.object({
  email: z.string().email('Email is not valid'),
  password: z
    .string()
    .min(8, 'Password must have at least 8 characters')
    .max(25, 'Password must have at most 25 characters')
});

const SignIn = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Credentials>({ resolver: zodResolver(authSchema) });

  const onSubmit = (credentials: Credentials) => {
    signIn(credentials).then(() => navigate('/admin'));
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant='h4'
          gutterBottom
        >
          Sign In
        </Typography>
        <Grid2
          container
          spacing={2}
        >
          {Object.keys(authSchema.shape).map((key) => (
            <Grid2
              size={{ xs: 12, sm: 6 }}
              key={key}
            >
              <Controller
                name={key as keyof Credentials}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    variant='outlined'
                    type={key === 'password' ? 'password' : 'email'}
                    fullWidth
                    error={!!errors[key as keyof Credentials]}
                    helperText={errors[key as keyof Credentials]?.message}
                  />
                )}
              />
            </Grid2>
          ))}
          <Grid2 size={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              Sign In
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </Paper>
  );
};

export { SignIn };
