import { Visibility } from '@mui/icons-material';
import { Button, Grid2 } from '@mui/material';
import { useState } from 'react';
import { AdminViewImageModal } from '.';

type Props = {
  text: string;
  imageId?: string;
};

const AdminViewImageButton = ({ text, imageId }: Props) => {
  const [isViewingImage, setIsViewingImage] = useState(false);
  return (
    <>
      <Grid2 size={{ xs: 4, sm: 2 }}>
        <Button
          fullWidth
          sx={{ height: '100%' }}
          component='label'
          variant='contained'
          disabled={!imageId}
          color={'info'}
          startIcon={<Visibility />}
          onClick={() => setIsViewingImage(true)}
        >
          {text}
        </Button>
      </Grid2>
      <AdminViewImageModal
        isOpen={isViewingImage}
        setIsViewingImage={setIsViewingImage}
        imageId={imageId as string}
      />
    </>
  );
};

export { AdminViewImageButton };
