import { createContext } from 'react';
import { Credentials, User } from '../globals/types';

type AuthContextType = {
  user: User | undefined;
  signUp(user: User): Promise<User>;
  signIn(credentials: Credentials): Promise<User>;
  signOut(): void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export { AuthContext };
