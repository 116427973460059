import { Box, Container, Paper, Typography } from '@mui/material';
import { AdminBreadcrumbs } from '.';

type Props = {
  title: string;
  width?: string;
  children: React.ReactNode;
};

const AdminPaper = ({ title, width, children }: Props) => (
  <Container>
    <Box
      padding={3}
      width={width || 'calc(100vw - 18px)'}
    >
      <Paper>
        <Box padding={3}>
          <AdminBreadcrumbs />
          <Typography
            variant='h4'
            gutterBottom
          >
            {title}
          </Typography>
          {children}
        </Box>
      </Paper>
    </Box>
  </Container>
);

export { AdminPaper };
