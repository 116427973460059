import { Box, Container, Dialog } from '@mui/material';
import { useCloudflare, useScreen } from '../../../hooks';

type Props = {
  isOpen: boolean;
  setIsViewingImage: (value: React.SetStateAction<boolean>) => void;
  imageId: string;
};

const AdminViewImageModal = ({ isOpen, setIsViewingImage, imageId }: Props) => {
  const { getImageUrl } = useCloudflare();
  const { isMobile } = useScreen();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsViewingImage(false)}
      maxWidth='xl'
    >
      <Container>
        <Box
          width={{ xs: 600, sm: 1200 }}
          sx={{
            backgroundColor: 'darkgray',
            backgroundImage: `linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0),
          linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0)`,
            backgroundSize: '40px 40px',
            backgroundPosition: '0 0, 20px 20px',
            lineHeight: 0
          }}
        >
          <img
            src={getImageUrl(imageId, isMobile ? '600x600' : '1200x1200')}
            alt='image'
            style={{
              height: '100%',
              width: '100%',
              maxWidth: isMobile ? '400px' : '1200px',
              maxHeight: isMobile ? '400px' : '600px',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Container>
    </Dialog>
  );
};

export { AdminViewImageModal };
