import { Box, Button, Container, Grid2, Modal } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignIn, SignOut } from '../../components/auth';
import { useAuth } from '../../hooks';
import { AdminPaper } from './components';

const adminSections = ['brands', 'distributors', 'products', 'retailers', 'subscribers', 'users'];

const AdminHome = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback((open: boolean) => setShowModal(open), []);
  const handleNavigation = useCallback((section: (typeof adminSections)[number]) => navigate(section), [navigate]);

  return (
    <Container>
      <Box>
        <Button
          type='button'
          variant='contained'
          onClick={() => handleShowModal(true)}
        >
          Sign {user ? 'Out' : 'In'}
        </Button>
        <Modal
          open={showModal}
          onClose={() => handleShowModal(false)}
        >
          <Box>{user ? <SignOut /> : <SignIn />}</Box>
        </Modal>
      </Box>
      {user && user.role === 'admin' && (
        <AdminPaper title='Admin Dashboard'>
          <Grid2
            container
            spacing={2}
          >
            {adminSections.map((section) => (
              <Grid2
                key={section}
                size={{ xs: 12, sm: 6, md: 4 }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => handleNavigation(section)}
                >
                  {section}
                </Button>
              </Grid2>
            ))}
          </Grid2>
        </AdminPaper>
      )}
    </Container>
  );
};

export { AdminHome };
