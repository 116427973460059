import { Delete, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { characters } from '../../../globals/constants';
import { Retailer } from '../../../globals/types';
import { Character } from '../../../globals/types/Character';
import { useCloudflare, useRetailers } from '../../../hooks';
import { AdminConfirmationModal, AdminError, AdminLoading, AdminPaper, AdminTablePagination } from '../components';
import { AdminEditRetailerModal } from '../components/AdminEditRetailerModal';
import { AdminSearchInput } from '../components/AdminSearchInput';
import { AdminTableAlphabeticalIndex } from '../components/AdminTableAlphabeticalIndex';

type SortByOptions = 'name' | 'url' | 'scraperPriority';

type SortDirection = 'asc' | 'desc';

const AdminListRetailers = () => {
  const queryClient = useQueryClient();
  const { getImageUrl } = useCloudflare();
  const { fetchAllRetailers, searchRetailers, deleteRetailer } = useRetailers();

  const [pages, setPages] = useState<number>(10);
  const [itemsCount, setItemsCount] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEditRetailerModalOpen, setIsEditRetailerModalOpen] = useState(false);
  const [retailerToRemove, setRetailerToRemove] = useState<Retailer>();
  const [retailerIdToUpdate, setRetailerIdToUpdate] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: 'name',
    sortDirection: 'asc',
    page: '1',
    term: '',
    character: ''
  });

  const {
    data: retailers = [],
    isLoading,
    error: fetchAllRetailersError,
    refetch: refetchRetailers
  } = useQuery({
    queryKey: ['fetchRetailers', searchParams.get('sort'), searchParams.get('sortDirection'), searchParams.get('page')],
    queryFn: async () => {
      let response;
      const queryParams = {
        sortBy: searchParams.get('sortBy') || undefined,
        sortDirection: searchParams.get('sortDirection') || undefined,
        page: Number(searchParams.get('page')) || undefined,
        character: (searchParams.get('character') as Character) || undefined
      };
      if (searchParams.get('term')) {
        response = await searchRetailers(searchParams.get('term') as string, queryParams);
      } else {
        response = await fetchAllRetailers(queryParams);
      }
      setPages(Math.ceil(response.totalCount / 30));
      setItemsCount(response.totalCount);
      return response.retailers;
    }
  });

  const { mutate: mutateRetailer, error: deleteRetailerError } = useMutation({
    mutationFn: (retailerId: string) => deleteRetailer(retailerId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRetailers'] });
      setIsConfirmationModalOpen(false);
      setRetailerToRemove(undefined);
    }
  });

  const truncateUrl = (url: string) => {
    return url?.replace('https://', '').replace('http://', '');
  };

  const handleSort = (option: SortByOptions) => {
    setSearchParams({
      sortBy: option,
      sortDirection: searchParams.get('sortDirection') === 'asc' ? 'desc' : 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      term: searchParams.get('term') || ''
    });
  };

  const onSubmit = async (data: { term: string }) => {
    setSearchParams({
      sortBy: searchParams.get('sortBy') || 'name',
      sortDirection: searchParams.get('sortDirection') || 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      term: data.term || ''
    });
  };

  useEffect(() => {
    refetchRetailers();
  }, [refetchRetailers, searchParams]);

  return (
    <Container>
      <AdminPaper title='Retailers'>
        <AdminError error={fetchAllRetailersError || deleteRetailerError} />
        <Box textAlign='center'>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={() => setIsEditRetailerModalOpen(true)}
          >
            Create
          </Button>
        </Box>
        <AdminSearchInput
          defaultValue={searchParams.get('term') as string}
          onSubmit={onSubmit}
        />
        <Box>
          <Table padding='checkbox'>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <AdminTableAlphabeticalIndex
                    colSpan={7}
                    character={(searchParams.get('character') as Character) || undefined}
                    onChange={(characterIndex) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: '1',
                        term: searchParams.get('term') || '',
                        character:
                          characters[characterIndex - 1] === searchParams.get('character')
                            ? ''
                            : characters[characterIndex - 1]
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <Typography
                    variant='body2'
                    textAlign='center'
                    pb={2}
                  >
                    {itemsCount} items
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'name'}
                    direction={
                      searchParams.get('sortBy') === 'name'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : undefined
                    }
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'url'}
                    direction={
                      searchParams.get('sortBy') === 'url'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('url')}
                  >
                    Url
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'scraperPriority'}
                    direction={
                      searchParams.get('sortBy') === 'scraperPriority'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('scraperPriority')}
                  >
                    Priority
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Logo</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Hero</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Scraper</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <AdminLoading isLoading={isLoading} />
                  </TableCell>
                </TableRow>
              ) : null}
              {retailers.map(({ _id, name, slug, url, scraperPriority, heroImage, logo, scraperStatus }) => (
                <TableRow key={_id}>
                  <TableCell>
                    <Link
                      underline='none'
                      onClick={() => {
                        setRetailerIdToUpdate(_id);
                        setIsEditRetailerModalOpen(true);
                      }}
                    >
                      {name}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    <Link
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      {truncateUrl(url || '')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    <Typography>{scraperPriority}</Typography>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    {logo ? (
                      <Link
                        href={getImageUrl(logo, '400x400')}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline='none'
                      >
                        <Visibility color='action' />
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    {heroImage ? (
                      <Link
                        href={getImageUrl(heroImage, '2000x614')}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline='none'
                      >
                        <Visibility color='action' />
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    <Typography>{scraperStatus}</Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          setIsConfirmationModalOpen(true);
                          setRetailerToRemove({ _id, name, slug });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <AdminTablePagination
                    pages={pages}
                    page={Number(searchParams.get('page'))}
                    onChange={(page) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: page.toString(),
                        term: searchParams.get('term') || '',
                        character: searchParams.get('character') || ''
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </AdminPaper>
      <AdminEditRetailerModal
        isOpen={isEditRetailerModalOpen}
        retailerId={retailerIdToUpdate}
        onSuccess={() => {
          refetchRetailers();
          setRetailerIdToUpdate(undefined);
          setIsEditRetailerModalOpen(false);
        }}
        onCancel={() => {
          setRetailerIdToUpdate(undefined);
          setIsEditRetailerModalOpen(false);
        }}
      />
      <AdminConfirmationModal
        isOpen={isConfirmationModalOpen}
        text={`Do you want to remove ${retailerToRemove?.name}?`}
        confirmAction={() => mutateRetailer(retailerToRemove?._id as string)}
        cancelAction={() => {
          setIsConfirmationModalOpen(false);
          setRetailerToRemove(undefined);
        }}
      />
    </Container>
  );
};

export { AdminListRetailers };
