import { Subscriber } from '../globals/types';
import { api } from '../services';

export type PatchSubscriberProps = Partial<Subscriber> & { _id: string };

const subscribersApiPath = '/subscribers';

const useSubscribers = () => {
  const fetchAllSubscribers = async (): Promise<(Subscriber & { _id: string })[]> => {
    const { data } = await api.get(subscribersApiPath);
    return data;
  };

  const fetchSubscriber = async (subscriberId: string): Promise<Subscriber> => {
    const { data } = await api.get(`${subscribersApiPath}/${subscriberId}`);
    return data;
  };

  const createSubscriber = async (subscriber: Partial<Subscriber>): Promise<Subscriber> => {
    const { data } = await api.post(`${subscribersApiPath}`, subscriber);
    return data;
  };

  const editSubscriber = async (subscriber: PatchSubscriberProps): Promise<Subscriber> => {
    const { data } = await api.patch(`${subscribersApiPath}/${subscriber._id}`, subscriber);
    return data;
  };

  return { fetchAllSubscribers, fetchSubscriber, createSubscriber, editSubscriber };
};

export { useSubscribers };
