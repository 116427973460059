import { api } from '../services';

type CloudflareImageVariant =
  | '200x200'
  | '400x400'
  | '600x600'
  | '800x800'
  | '1200x1200'
  | '2000x2000'
  | '600x185'
  | '2000x614'
  | '4000x1227';

const ACCOUNT_HASH = import.meta.env.VITE_CLOUDFLARE_ACCOUNT_HASH;

const useCloudflare = () => {
  const uploadImage = async (image: File, metadata: { [key: string]: string }): Promise<string | undefined> => {
    try {
      const uploadUrlResponse = await api.post('media/generate-cloudflare-direct-upload-url', metadata);
      const { uploadURL, id: imageId } = uploadUrlResponse.data.result;
      const form = new FormData();
      form.append('file', image);
      const response = await fetch(uploadURL, { method: 'post', body: form });
      if (response.ok) {
        return imageId;
      } else {
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = async (imageId: string) => {
    try {
      await api.delete(`media/images/${imageId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getImageUrl = (imageId: string, variant: CloudflareImageVariant) => {
    return `https://imagedelivery.net/${ACCOUNT_HASH}/${imageId}/${variant}`;
  };

  return { uploadImage, getImageUrl, deleteImage };
};

export { useCloudflare };
