import { Container, Grid2 } from '@mui/material';

type Props = {
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  children: React.ReactNode;
};

const AdminForm = ({ onSubmit, children }: Props) => (
  <Container>
    <form onSubmit={onSubmit}>
      <Grid2
        container
        spacing={2}
      >
        {children}
      </Grid2>
    </form>
  </Container>
);

export { AdminForm };
