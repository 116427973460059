import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

type Props = {
  onSubmit: (data: { term: string }) => Promise<void>;
  defaultValue: string;
};

const AdminSearchInput = ({ onSubmit, defaultValue }: Props) => {
  const searchTermSchema = z.object({ term: z.string() });
  const { control, handleSubmit } = useForm<{ term: string }>({ resolver: zodResolver(searchTermSchema) });

  return (
    <Box textAlign='center'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='term'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              defaultValue={defaultValue}
              label='Search'
              variant='outlined'
              margin='normal'
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '50%'
                }
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Button
                        variant='contained'
                        type='submit'
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  )
                }
              }}
            />
          )}
        />
      </form>
    </Box>
  );
};

export { AdminSearchInput };
