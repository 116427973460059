import { Button, Grid2, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks';

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  return (
    <Paper>
      <Typography
        variant='h4'
        gutterBottom
      >
        Sign Out
      </Typography>
      <Grid2
        container
        spacing={2}
      >
        <Grid2
          size={{ xs: 12, sm: 6 }}
          key='yes'
        >
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={() => {
              signOut();
              navigate('/');
            }}
          >
            Yes
          </Button>
          <Button
            type='button'
            variant='contained'
            color='primary'
          >
            Cancel
          </Button>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export { SignOut };
