import { Box, Typography } from '@mui/material';
import { memo } from 'react';

type Props = {
  error: Error | null;
};

const AdminError = memo(({ error = null }: Props) => {
  if (!error) return null;
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100px'
    >
      <Typography color='error'>{error.message}</Typography>
    </Box>
  );
});

export { AdminError };
