import { Delete } from '@mui/icons-material';
import { Box, Button, Dialog, Grid2, Typography } from '@mui/material';
import { useState } from 'react';

type Props = {
  text: string;
  disabled: boolean;
  onDelete: () => void;
};

const AdminDeleteImageButton = ({ text, disabled, onDelete }: Props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  return (
    <>
      <Grid2 size={{ xs: 4, sm: 2 }}>
        <Button
          fullWidth
          sx={{ height: '100%' }}
          component='label'
          variant='contained'
          disabled={disabled}
          color={'error'}
          startIcon={<Delete />}
          onClick={() => setIsConfirmModalOpen(true)}
        >
          Delete {text}
        </Button>
      </Grid2>
      <Dialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <Box padding={4}>
          <Typography
            variant='h4'
            textAlign='center'
            gutterBottom
          >
            Delete {text}?
          </Typography>
          <Grid2
            container
            textAlign='center'
            pt={3}
            spacing={2}
            size={12}
          >
            <Grid2 size={6}>
              <Button
                onClick={() => {
                  onDelete();
                  setIsConfirmModalOpen(false);
                }}
                variant='contained'
                color='primary'
                sx={{ minWidth: { xs: 110, md: 200 } }}
              >
                Yes, delete
              </Button>
            </Grid2>
            <Grid2 size={6}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={() => setIsConfirmModalOpen(false)}
                sx={{ minWidth: { xs: 110, md: 200 } }}
              >
                Cancel
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Dialog>
    </>
  );
};

export { AdminDeleteImageButton };
