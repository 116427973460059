import { Box, Dialog, Paper, Typography } from '@mui/material';
import { AdminEditRetailerForm } from '../../../components/admin';
import { Retailer } from '../../../globals/types';

type Props = {
  isOpen: boolean;
  retailerId?: string;
  onSuccess: (retailer: Retailer) => void;
  onCancel: () => void;
};

const AdminEditRetailerModal = ({ isOpen, retailerId, onSuccess, onCancel }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth={false}
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Paper>
        <Box padding={3}>
          <Typography
            variant='h4'
            gutterBottom
          >
            {retailerId ? 'Edit' : 'Create'} Retailer
          </Typography>
          <AdminEditRetailerForm
            retailerId={retailerId}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        </Box>
      </Paper>
    </Dialog>
  );
};

export { AdminEditRetailerModal };
