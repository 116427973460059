import { createTheme, ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1C4D96',
      light: '#607EC8',
      dark: '#062863'
    },
    secondary: {
      main: '#A0811D',
      light: '#D4AF37'
    }
  },
  typography: {
    fontFamily: 'ABeeZee',
    fontWeightLight: 400,
    fontSize: 15,
    h1: {
      fontFamily: 'Manrope',
      fontSize: 56,
      fontWeight: 700
    },
    h2: {
      fontFamily: 'Manrope',
      fontSize: 40,
      fontWeight: 700
    },
    h3: {
      fontFamily: 'ABeeZee',
      fontSize: 32,
      fontWeight: 700
    },
    h4: {
      fontFamily: 'Manrope',
      fontSize: 28,
      fontWeight: 700
    },
    h5: {
      fontFamily: 'Manrope',
      fontSize: 24,
      fontWeight: 700
    },
    h6: {
      fontFamily: 'Manrope',
      fontSize: 18,
      fontWeight: 700
    },
    overline: {
      fontFamily: 'Manrope',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 1.1
    },
    subtitle1: {
      fontFamily: 'Manrope',
      fontSize: 13,
      fontWeight: 700
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          display: 'flex',
          placeItems: 'center',
          backgroundColor: '#F5F5F5'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0
        }
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
          maxWidth: '100vw'
        }
      }
    },
  }
};

const theme = createTheme(themeOptions);

export { theme };

