import '@fontsource/abeezee';
import '@fontsource/eb-garamond/400-italic.css';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
