import { useParams } from 'react-router-dom';
import { AdminEditBrandForm } from '../../../components/admin/AdminEditBrandForm';
import { AdminPaper } from '../components';

const AdminEditBrand = () => {
  const { brandId } = useParams<{ brandId?: string }>();

  return (
    <AdminPaper title={`${brandId ? 'Edit' : 'Create'} Brand`}>
      <AdminEditBrandForm brandId={brandId} />
    </AdminPaper>
  );
};

export { AdminEditBrand };
