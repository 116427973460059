import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, Grid2, Modal, Paper, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { AdminError, AdminField, AdminFormActions, AdminLoading } from '.';
import { Retailer } from '../../../globals/types';
import { useRetailers } from '../../../hooks';
import { retailerSchema } from '../retailers/schemas';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRetailers: React.Dispatch<React.SetStateAction<Retailer[]>>;
};

const AdminQuickAddRetailerModal = ({ isOpen, setIsOpen, setSelectedRetailers }: Props) => {
  const queryClient = useQueryClient();
  const { createRetailer } = useRetailers();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty }
  } = useForm<Retailer>({ resolver: zodResolver(retailerSchema) });

  const { isPending, mutate, error } = useMutation({
    mutationFn: (retailer: Retailer) => createRetailer({ ...retailer }),
    onSuccess: ({ _id, name, slug }) => {
      queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllRetailers'] });
      setSelectedRetailers((prev) => [...prev, { _id, name, slug }]);
      setIsOpen(false);
    }
  });

  const onSubmit = (data: Retailer) => {
    mutate(data as Retailer);
  };

  return (
    <Modal open={isOpen}>
      <Container>
        <Box padding={3}>
          <Paper>
            <Box padding={3}>
              <Typography
                variant='h4'
                gutterBottom
              >
                Quick Add Retailer
              </Typography>
              <AdminLoading isLoading={isPending} />
              <AdminError error={error} />
              {!isPending && !error ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid2
                    container
                    spacing={2}
                  >
                    <AdminField
                      id='name'
                      key='name'
                      label='Name'
                      control={control}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      onChange={(e) => {
                        setValue(
                          'slug',
                          e.target.value
                            .replace(/[^\w\s-]/g, '')
                            .trim()
                            .split(' ')
                            .join('-')
                            .toLowerCase()
                        );
                        setValue('name', e.target.value);
                      }}
                    />
                    <AdminField
                      id='slug'
                      key='slug'
                      label='Slug'
                      control={control}
                      error={!!errors.slug}
                      helperText={errors.slug?.message}
                    />
                    <AdminField
                      id='url'
                      key='url'
                      label='Url'
                      type='url'
                      control={control}
                      error={!!errors.url}
                      helperText={errors.url?.message}
                      onChange={(e) => {
                        const text = e.target.value.trim().toLowerCase();
                        if (['https:/', 'https:', 'http', 'htt', 'ht', 'h'].includes(text)) {
                          setValue('url', 'https://');
                        } else {
                          setValue('url', `${text.startsWith('https://') ? '' : 'https://'}${text}`);
                        }
                      }}
                    />
                  </Grid2>
                  <AdminFormActions
                    saveAction={handleSubmit(onSubmit)}
                    cancelAction={() => setIsOpen(false)}
                    isSaveButtonDisabled={!isDirty}
                  />
                </form>
              ) : (
                <></>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Modal>
  );
};

export { AdminQuickAddRetailerModal };
