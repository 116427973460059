import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Container, Grid2, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Footer } from '../components/globals/Footer';
import { FollowUsVertical } from '../components/socials/FollowUsVertical';
import { Subscriber } from '../globals/types';
import { useGeolocation, useScreen, useSubscribers } from '../hooks';

const Home = () => {
  const { isMobile } = useScreen();
  const { fetchGeolocation } = useGeolocation();
  const { createSubscriber } = useSubscribers();
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { mutate } = useMutation({
    mutationFn: (subscriber: Subscriber) => createSubscriber({ ...subscriber }),
    onSuccess: ({ email }) => {
      setIsSubscribed(true);
      window.open(`https://form.jotform.com/242146733475055?email=${email}`, '_blank', 'noopener,noreferrer');
    },
    onError: (error) => {
      setError('email', { message: error.message });
    }
  });

  const subscriberSchema = z.object({ email: z.string().email('Must be a valid email') });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{ email: string }>({ resolver: zodResolver(subscriberSchema) });

  const onSubmit = async (data: Omit<Subscriber, '_id'>) => {
    data.testing = data.email.includes('audiophiledirectory.com');
    try {
      const { country_code2: country, state_prov: state, city } = await fetchGeolocation();
      data = { ...data, country, state, city };
    } catch (error) {
      console.error(error);
    }
    mutate(data as Subscriber);
  };

  return (
    <Container>
      <Box
        height={820}
        width='100%'
        justifyContent='center'
        flexDirection='column'
        display='flex'
        alignItems='center'
        sx={{
          backgroundImage: `linear-gradient(to bottom, #000000C0 0%, #00000000 500px),
            linear-gradient(to top, #000000C0 0%, #00000000 300px),
            url(/assets/home1.png)`,
          backgroundSize: 'cover'
        }}
      >
        <FollowUsVertical />
        <Box
          alignItems='center'
          width={239}
          height={262}
          mt={{
            xs: 0,
            sm: 1,
            lg: 3
          }}
          sx={{ background: 'url(assets/logo-centered-negative.svg) no-repeat' }}
        />
        <Typography
          variant='h1'
          color='white'
          fontFamily='ABeeZee'
          fontSize={{ xs: 35, sm: 45, md: 63, lg: 74 }}
          fontWeight={400}
          letterSpacing={{ xs: 1, md: 4 }}
          textAlign={{ xs: 'center', md: 'left' }}
          pt={5}
          pb={6}
        >
          Everything, One Place, {isMobile ? '' : '\u00A0\u00A0\u00A0\u00A0'}
          <br />
          <span style={{ float: isMobile ? undefined : 'right' }}>All At Once</span>
        </Typography>
        <Box
          alignItems='center'
          maxWidth={{ xs: '95%', md: 650 }}
        >
          <Link
            href='https://form.jotform.com/242146733475055'
            target='_blank'
            rel='noopener noreferrer'
            underline='none'
          >
            <Typography
              variant='h4'
              fontFamily='ABeeZee'
              color='secondary.light'
              textAlign={{ xs: 'center', md: 'left' }}
              fontSize={{ xs: 20, md: 23 }}
              fontWeight={100}
              lineHeight={1.4}
              letterSpacing={{ xs: 1, md: 2.2 }}
              textTransform='uppercase'
              fontStyle='italic'
              pb={5}
              sx={{ u: { textDecorationThickness: 6, textUnderlineOffset: 12 } }}
            >
              {isMobile ? (
                <>
                  Join our audiophile community and enter to win dream prizes by taking our <u>survey</u>!
                </>
              ) : (
                <>
                  Join our audiophile community and enter to
                  <br />
                  <u>win dream prizes</u> by taking our survey!
                </>
              )}
            </Typography>
          </Link>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          mt={2}
          mb={4}
        >
          {isSubscribed ? (
            <Typography
              variant='h6'
              fontFamily='ABeeZee'
              fontSize={20}
              color='white'
              textAlign='center'
              letterSpacing={1.5}
              textTransform='uppercase'
            >
              Thanks for being part of our community!
            </Typography>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    placeholder='Enter your email'
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={{
                      backgroundColor: '#FFFFFF60',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      borderRadius: 1,
                      width: {
                        xs: '100%',
                        sm: 300,
                        md: 500
                      }
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Button
                              variant='contained'
                              color='secondary'
                              type='submit'
                              sx={{
                                color: 'white',
                                fontSize: 13,
                                padding: '10px 30px'
                              }}
                            >
                              Subscribe
                            </Button>
                          </InputAdornment>
                        )
                      }
                    }}
                  />
                )}
              />
            </form>
          )}
        </Box>
      </Box>
      <Box
        width='100%'
        justifyContent='center'
        flexDirection='column'
        display='flex'
        alignItems='center'
        sx={{
          backgroundImage: 'url(/assets/home2.png)', // Add the path to your background image
          backgroundSize: 'cover',
          backgroundPosition: '20% 40%'
        }}
      >
        <Box
          bgcolor='#00000090'
          width='100%'
          height='100%'
        >
          <Typography
            variant='h6'
            maxWidth='95%'
            fontFamily='ABeeZee'
            fontSize={20}
            color='white'
            textAlign='center'
            letterSpacing={1.5}
            textTransform='uppercase'
            pt={4}
            pb={3}
          >
            partner with us and reach the whole audiophile community!
          </Typography>
          <Grid2
            container
            spacing={2}
            justifyContent='center'
            pb={4}
          >
            {['Brands', 'Retailers', 'Distributors'].map((typeOfBusiness) => (
              <Grid2
                key={`survey-${typeOfBusiness}`}
                size={{ xs: 8, sm: 5, md: 3 }}
              >
                <Box
                  bgcolor='#00000070'
                  pl={2}
                  pr={2}
                  pt={4}
                  pb={4}
                  sx={{ backdropFilter: 'blur(4px)' }}
                >
                  <Link
                    href='https://form.jotform.com/242146545705052'
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='none'
                  >
                    <Typography
                      variant='h4'
                      fontFamily='ABeeZee'
                      letterSpacing={4}
                      color='secondary.light'
                      textTransform='uppercase'
                      align='center'
                    >
                      {typeOfBusiness}
                    </Typography>
                    <Typography
                      variant='body2'
                      fontFamily='ABeeZee'
                      letterSpacing={2}
                      color='white'
                      textTransform='uppercase'
                      align='right'
                      pt={3}
                      sx={{
                        b: {
                          color: 'secondary.light'
                        }
                      }}
                    >
                      Get featured <b>{`>`}</b>
                    </Typography>
                  </Link>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Box>
      <Box
        width='100%'
        justifyContent='center'
        flexDirection='column'
        display='flex'
        alignItems='left'
        sx={{
          backgroundImage: 'url(/assets/home3.png)',
          backgroundSize: 'cover'
        }}
      >
        <Box padding={10}>
          <Typography
            variant='h2'
            fontFamily='ABeeZee'
            color='primary.light'
            fontWeight={400}
          >
            We Are Building The Definitive Audiophile Directory
          </Typography>
          <Box
            width={{ xs: 150, sm: 150, md: 310 }}
            height={6}
            bgcolor='secondary.light'
            mt={3}
          />
          <Box
            display='flex'
            pt={3}
          >
            <Typography
              variant='body1'
              color='grey.800'
            >
              <b>Welcome to The Audiophile Directory</b>
              <br />
              <br />
              We are lifelong audiophiles with a passion for high-fidelity sound, and our mission is to connect and
              elevate the world of audio by uniting enthusiasts, brands, and retailers in one dynamic community. In a
              fragmented industry, our directory serves as a vital hub, making it easy for everyone — whether you are a
              seasoned pro or just starting out — to discover the best in audio equipment.
              <br />
              <br />
              We are here to support the entire audio community, driving traffic to leading brands, championing voices
              from magazines, forums, and YouTubers, highlighting passionate artisans and most importantly, helping
              brick-and-mortar retailers thrive in the digital age. Join us as we build the ultimate directory where the
              love for music and quality sound brings us all together.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export { Home };
