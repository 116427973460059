import { Country } from '../types';

const countries: Country[] = [
  'United States',
  'Albania',
  'Australia',
  'Austria',
  'Belgium',
  'Belarus',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Canada',
  'China',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'India',
  'Ireland',
  'Italy',
  'Japan',
  'Korea',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Mexico',
  'Moldova',
  'Montenegro',
  'Netherlands',
  'New Zealand',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Ukraine',
  'United Kingdom'
];

export { countries };
