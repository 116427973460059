import { useState } from 'react';
import { AuthContext } from '../contexts';
import { Credentials, User } from '../globals/types';
import { api } from '../services';

const authApiPath = '/auth';
const usersApiPath = '/users';
const localStorageUserKey = '@audiophile-directory:user';

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | undefined>();

  const signUp = async (user: User) => {
    await api.post(usersApiPath, user);
    return signIn({ email: user.email, password: user.password });
  };

  const signIn = async (credentials: Credentials): Promise<User> => {
    const { data } = await api.post(authApiPath, credentials);
    if (data.access_token) {
      api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      localStorage.setItem(localStorageUserKey, JSON.stringify(data));
      setUser(data.user);
    }
    return data;
  };

  const signOut = async () => {
    try {
      await api.post(`${authApiPath}/logout`);
    } catch (error) {
      console.error(error);
    }
    delete api.defaults.headers.common.Authorization;
    localStorage.removeItem(localStorageUserKey);
    setUser({} as User);
  };

  const loadUserFromLocalStorage = () => {
    if (localStorage.getItem(localStorageUserKey)) {
      const localStorageUser = JSON.parse(String(localStorage.getItem(localStorageUserKey)));
      verifyAuth(localStorageUser.access_token);
      if (!user) {
        setUser(localStorageUser.user);
        api.defaults.headers.common.Authorization = `Bearer ${localStorageUser.access_token}`;
      }
    }
  };

  const verifyAuth = (accessToken: string) => {
    const decodedJwt = parseJwt(accessToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      signOut();
    }
  };

  const parseJwt = (accessToken: string) => {
    try {
      return JSON.parse(window.atob(accessToken.split('.')[1]));
    } catch (e) {
      console.error('Error parsing JWT', e);
      return null;
    }
  };

  loadUserFromLocalStorage();

  return <AuthContext.Provider value={{ user, signUp, signIn, signOut }}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
