import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSubscribers } from '../../../hooks';
import { AdminError, AdminLoading, AdminPaper } from '../components';

const AdminListSubscribers = () => {
  const navigate = useNavigate();
  const { fetchAllSubscribers } = useSubscribers();
  const {
    data: subscribers,
    isLoading,
    error
  } = useQuery({ queryKey: ['fetchAllSubscribers'], queryFn: fetchAllSubscribers });

  if (isLoading) return <AdminLoading isLoading={isLoading} />;
  if (error) return <AdminError error={error} />;

  if (subscribers) {
    return (
      <AdminPaper title='Subscribers'>
        <Box textAlign='center'>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={() => navigate('create')}
          >
            Create
          </Button>
        </Box>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscribers.map(({ _id, email, country, state }) => (
                <TableRow key={_id}>
                  <TableCell>
                    <Button
                      type='button'
                      variant='text'
                      onClick={() => navigate(`${_id}`)}
                    >
                      {email}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Typography>{country}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{state}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AdminPaper>
    );
  }
};

export { AdminListSubscribers };
