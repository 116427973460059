import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, IconButton, Link, Typography } from '@mui/material';

const FollowUsVertical = () => (
  <Box
    position='absolute'
    left={{ xs: 30, md: 70 }}
    top={{ xs: 30, md: 70 }}
    alignItems='center'
  >
    <Box sx={{ writingMode: 'vertical-lr', color: '#fff' }}>
      <Typography
        variant='h6'
        fontFamily='ABeeZee'
        fontWeight={400}
        fontStyle='italic'
        paddingBottom={2}
      >
        Follow us
      </Typography>
    </Box>
    <Box
      sx={{ writingMode: 'vertical-lr', color: '#fff' }}
      paddingBottom={1}
    >
      <Link
        href='https://www.facebook.com/audiophiledirectory'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IconButton
          size='small'
          sx={{ color: '#fff', transform: 'rotate(90deg)' }}
        >
          <FacebookIcon fontSize='small' />
        </IconButton>
      </Link>
    </Box>
    <Link
      href='https://www.instagram.com/audiophiledirectory'
      target='_blank'
      rel='noopener noreferrer'
    >
      <IconButton
        size='small'
        sx={{ color: '#fff', transform: 'rotate(90deg)' }}
      >
        <InstagramIcon fontSize='small' />
      </IconButton>
    </Link>
  </Box>
);

export { FollowUsVertical };
