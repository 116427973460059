import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box
    height={36}
    textAlign='center'
    bgcolor='primary.dark'
    color='white'
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    <Typography variant='body2'>© 2024 Audiophile Directory LLC</Typography>
  </Box>
);

export { Footer };
