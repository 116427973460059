import { Button, Grid2 } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type Props = {
  saveAction: () => void;
  cancelAction?: () => void;
  isSaveButtonDisabled?: boolean;
};

const AdminFormActions = ({ saveAction, cancelAction, isSaveButtonDisabled }: Props) => {
  const navigate = useNavigate();

  return (
    <Grid2
      container
      textAlign='center'
      padding={3}
      size={12}
    >
      <Grid2 size={6}>
        <Button
          type='submit'
          onClick={saveAction}
          variant='contained'
          color='primary'
          disabled={isSaveButtonDisabled}
          sx={{ minWidth: { xs: 110, md: 200 } }}
        >
          Save
        </Button>
      </Grid2>
      <Grid2 size={6}>
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={cancelAction || (() => navigate(-1))}
          sx={{ minWidth: { xs: 110, md: 200 } }}
        >
          Cancel
        </Button>
      </Grid2>
    </Grid2>
  );
};

export { AdminFormActions };
