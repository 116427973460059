import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;

const api = axios.create({ baseURL });

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
);

export { api };
